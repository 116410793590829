import React, { createContext, useContext, useEffect, useState } from "react";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    app_name: "Maxchats",
    app_logo: "/images/LOGO_BOT.png",
    app_favicon: "/favicon.ico",
    billing_country: "Brazil",
    billing_address: "Rua dos Bobos, 0",
    billing_city: "São Paulo",
    billing_state: "SP",
    billing_zip: "00000-000",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/v1/settings/general");
        const data = await response.json();
        setSettings({
          app_name: data?.app_name ? data.app_name : "Maxchats",
          app_logo: data?.app_logo ? data.app_logo : "/images/LOGO_BOT.png",
          app_favicon: data?.app_favicon ? data.app_favicon : "/favicon.ico",
          billing_country: data?.billing_country
            ? data.billing_country
            : "Brazil",
          billing_address: data?.billing_address
            ? data.billing_address
            : "Rua dos Bobos, 0",
          billing_city: data?.billing_city ? data.billing_city : "São Paulo",
          billing_state: data?.billing_state ? data.billing_state : "SP",
          billing_zip: data?.billing_zip ? data.billing_zip : "00000-000",
        });
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
};
